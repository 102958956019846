import React, { useState } from "react";
import "../App.css";
import { Input, Button, message, Spin } from 'antd';
import authHeader from '../utils/authHeader';
import { callDocsApi } from '../utils/callApi';

const Login = (props) => {
  const [id, setId] = useState(null);
  const [pw, setPw] = useState(null);
  const [loading, setLoading] = useState(false);

  const login = async (id, pw) => {
    if (!(id && pw)) {
      message.error('Please enter id or password');
    } else {
      try {
        setLoading(true);

        const mojitokToken = authHeader(id, pw);
        const response = await callDocsApi(mojitokToken);

        sessionStorage.setItem('mToken', mojitokToken);

        setLoading(false);
        props.history.push('/docs', response);

      } catch (err) {
        setLoading(false);
        message.error(err.message);
      }
    }
  }

  return (
    <Spin spinning={loading} tip="loading...">
      <div className="login-wrapper">
        <Input
          placeholder="Please enter your id"
          value={id}
          onChange={(e) => setId(e.target.value)}
          onPressEnter={() => login(id, pw)}
        />
        <Input
          placeholder="Please enter your password"
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          onPressEnter={() => login(id, pw)}
        />
        <Button onClick={() => login(id, pw)}>Login</Button>
      </div>
    </Spin>
  );
}

export default Login;