const crypto = require('crypto');
/**
 * Tenor 뷰어 사이트의 인증 헤더 생성 함수
 * @param {string} id 사용자 아이디
 * @param {string} pw 사용자 비밀번호
 * @returns {string} Mojitok-Token 헤더에 들어갈 값
 */
export default function authHeader(id, pw) {
  let header = crypto.createHash('SHA256')
    .update(id, 'utf8')
    .update(pw, 'utf8')
    .digest('HEX');
  return "r:"+header;
}