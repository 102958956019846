import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const mToken = sessionStorage.getItem('mToken');

  if(rest.path === '/login') {
    return(
      <Route {...rest} render={props => (
        mToken ? 
          props.history.goBack()
          : <Component {...props}/>         
      )}/>
    )
  }else {
    return (
      <Route {...rest} render={props => (
        mToken ? 
          <Component {...props}/>
        : (
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }}/>
        )
      )}/>
    )
  }
}