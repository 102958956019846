import React, { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';
import { useLocation } from 'react-router-dom';
import { callDocsApi } from '../utils/callApi';
import YAML from 'yaml';

export const Docs = (props) => {
  const location = useLocation();

  return (
    <RedocStandalone spec={YAML.parse(location.state)} />
  )
}