const axios = require('axios').default;

export async function callDocsApi(mToken) {
  const serverUrl = process.env.REACT_APP_DOCS_API_URL;

  const response = await axios({
    url: serverUrl,
    method: 'GET',
    headers: {
      'mojitok-token': mToken,
    },
  })

  const responseYaml = response.data;

  if (responseYaml.statusCode === 401 && responseYaml.body.includes('Authentication Failed')) {
    throw new Error('Invalid id/password.');
  } else if (response.status !== 200) {
    throw new Error(responseYaml);
  } else {
    return responseYaml;
  }
}



