import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Login from './components/Login';
import { Docs } from './components/Docs';
import { PrivateRoute } from './components/PrivateRoute';
import { NoMatch } from './components/NoMatch';

function App() {
  const history = useHistory();

  return (
    <Switch>
      <PrivateRoute exact path="/" component={Login}/>
      <Route path="/login" >
        <PrivateRoute path="/login" component={Login} history={history}/>
      </Route>
      <Route path="/docs" >
        <PrivateRoute path="/docs" component={Docs} history={history}/>
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}

export default App;
